import React from 'react';
import {
    MuiThemeProvider,
    createMuiTheme
} from '@material-ui/core';
import 'typeface-roboto';
import NumpadInput from './components/NumpadInput';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import MainToolbar from './components/MainToolbar';
import Werkstoffnachweis from './components/Werkstoffnachweis';
import Login from './components/Login';
import Herstellerbescheinigung from './components/Herstellerbescheinigung';
import Schweissnahtpruefung from './components/Schweissnahtpruefung';
import UpdateNotificationFromServiceWorker from './containers/UpdateNotificationFromServiceWorker';
import { registeredModules } from './modules/registeredModules';
import ModuleSelection from './components/ModuleSelection';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2581C4'
        },
        secondary: {
            main: '#FABB00'
        },
    },
});

function App(props) {
    const { routes } = props;

    const [loggedIn, setLoggedIn] = React.useState(false);

    const onLogin = () => setLoggedIn(true);
    const onLogout = () => setLoggedIn(false);

    return (
        <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <MuiThemeProvider theme={theme}>
                <Router>
                    <MainToolbar loggedIn={loggedIn} onLogin={onLogin} onLogout={onLogout}/>
                    {!loggedIn ?
                        <Login onLogin={onLogin}/>
                        :
                        <Switch>
                            <Route path="/werkstoffnachweis">
                                <Werkstoffnachweis/>
                            </Route>
                            <Route path="/schweissnahtpruefung">
                                <Schweissnahtpruefung/>
                            </Route>
                            <Route path="/herstellerbescheinigung">
                                <Herstellerbescheinigung/>
                            </Route>
                            <Route path="/touch-input">
                                <NumpadInput/>
                            </Route>
                            {routes}
                            <Route path="/">
                                <ModuleSelection modules={registeredModules}/>
                            </Route>
                        </Switch>
                    }
                </Router>
                <UpdateNotificationFromServiceWorker/>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
