import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

const useStyles = makeStyles({
    counter: {
        padding: '0 10px'
    }
});

export default function Counter(props) {
    const { counter, onIncrement, onDecrement } = props;
    const classes = useStyles();

    return (
        <div>
            <Button color="primary" onClick={onDecrement}>
                <Remove/>
            </Button>
            <span className={classes.counter}>{counter}</span>
            <Button color="primary" onClick={onIncrement}>
                <Add/>
            </Button>
        </div>
    );
}