import { LOAD_MASTER_DATA } from '../actions';

const BaseActionDispatcherMiddleware = ({ dispatch, getState }) => next => async action => {
    next(action);

    switch (action.type) {
        case LOAD_MASTER_DATA:
            const state = getState();

            for (const loadingAction of state.base.loadMasterDataActions) {
                dispatch(loadingAction);
            }

            return;
        default:
            return;
    }
};

export default BaseActionDispatcherMiddleware;
