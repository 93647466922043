import BusinessCard from '../components/BusinessCard';
import { connect } from 'react-redux';
import module from '../module';

function mapStateToProps(state) {
    return {
        user: state[[module.reducersPrefix]].user
    };
}

export default connect(mapStateToProps)(BusinessCard);