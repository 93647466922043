import { connect } from 'react-redux';
import Counter from '../components/Counter';
import { decrementCounter, incrementCounter } from '../../../actions';

function mapStateToProps(state) {
    return {
        counter: state.base.counter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onIncrement: () => dispatch(incrementCounter()),
        onDecrement: () => dispatch(decrementCounter())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Counter);