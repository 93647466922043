import { SET_USER } from './actions';
import { combineReducers } from 'redux';

function user(state = {}, action) {
    switch (action.type) {
        case SET_USER:
            return action.user;
        default:
            return state;
    }
}

export default combineReducers({
    user
});