import { SET_TEMPERATURE } from './actions';
import { combineReducers } from 'redux';

function temperature(state = 20, action) {
    switch (action.type) {
        case SET_TEMPERATURE:
            return action.temperature;
        default:
            return state;
    }
}

export default combineReducers({
    temperature
});