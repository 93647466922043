import {AppBar, Grid, IconButton, Menu, MenuItem, Popover, Toolbar, Typography, Button} from '@material-ui/core';
import {AccountCircle, Menu as MenuIcon, Lock, Home} from '@material-ui/icons';
import React from 'react';
import {useHistory} from 'react-router-dom';
import LoadMasterDataButton from '../containers/LoadMasterDataButton';
import useStyles from '../styles';
import {version} from '../../package.json';

function MainToolbar(props) {
    const {loggedIn, onLogout} = props;

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);

    const onOpenMenu = event => {
        setMenuAnchor(event.currentTarget);
    };

    const onCloseMenu = () => {
        setMenuAnchor(null);
    };

    const onOpenPopover = event => {
        setPopoverAnchor(event.currentTarget);
    };

    const onClosePopover = () => {
        setPopoverAnchor(null);
    };

    const onNavigate = path => () => {
        history.push(path);
        onCloseMenu();
    };

    const history = useHistory();

    const classes = useStyles();

    const menuItems = [
        <MenuItem key="werkstoffnachweis" onClick={onNavigate('/werkstoffnachweis')}>Werkstoffnachweis</MenuItem>,
        <MenuItem key="schweissnahtpruefung" onClick={onNavigate('/schweissnahtpruefung')}>Schweißnahtprüfung</MenuItem>,
        <MenuItem key="herstellerbescheinigung" onClick={onNavigate('/herstellerbescheinigung')}>Dokumente</MenuItem>,
        <MenuItem key="touch-input" onClick={onNavigate('/touch-input')}>Demo: Touch-Eingabe</MenuItem>,
        <MenuItem key="logout" onClick={() => {
            onLogout();
            onCloseMenu();
        }}>Logout</MenuItem>
    ];

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={onCloseMenu}
            >
                {loggedIn ?
                    menuItems
                    :
                    <MenuItem onClick={onCloseMenu}>Login</MenuItem>
                }
            </Menu>
            <AppBar position="sticky">
                <Toolbar>
                    <Grid container justify="space-between" alignItems="center">
                        <div>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                        aria-controls="simple-menu" onClick={onOpenMenu}>
                                <MenuIcon/>
                            </IconButton>
                            <IconButton className={classes.menuButton} color="inherit" onClick={onNavigate('/')}>
                                <Home/>
                            </IconButton>
                        </div>
                        <Typography variant="h6" className={classes.title}>
                            Scanner-Client
                        </Typography>
                        <div>
                            <LoadMasterDataButton/>
                            {loggedIn ?
                                <IconButton className={classes.menuButton} color="inherit" onClick={onOpenPopover}>
                                    <AccountCircle/>
                                </IconButton>
                                : <IconButton className={classes.menuButton} color="inherit"><Lock/></IconButton>}
                        </div>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography className={classes.popover}>Hallo, Demo-User! v{version} <Button color="primary" onClick={() => {
                    onLogout();
                    onClosePopover();
                }}>Logout</Button></Typography>
            </Popover>
        </>
    );
}

export default MainToolbar;