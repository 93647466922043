import axios from 'axios';

import { GET_USER, setUser } from '../actions';

const UserServiceMiddleware = store => next => async action => {
    next(action);

    const getUsers = () => {
        return axios.get('https://jsonplaceholder.typicode.com/users');
    };

    switch (action.type) {
        case GET_USER:
            return await getUsers()
                .then(users => {
                    console.log(users);
                    const user = users.data[Math.floor(Math.random() * users.data.length)];
                    console.log(user);
                    next(setUser(user));
                })
                .catch();
        default:
            return;
    }
};

export default UserServiceMiddleware;