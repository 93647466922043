import React from 'react';
import {Checkbox, Container, FormControlLabel, Paper, TextField, Typography, Button, Grid} from '@material-ui/core';
import {AddCircle, Cancel} from '@material-ui/icons';
import useStyles from '../styles';

function Werkstoffnachweis(props) {
    const classes = useStyles();

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Werkstoffnachweis</Typography>
                <TextField
                    fullWidth
                    label="System-ID"
                    className={classes.textField}
                    margin="normal"
                />
                <TextField
                    label="Position"
                    fullWidth
                    multiline
                    rows="3"
                    className={classes.textField}
                    margin="normal"
                />
                <FormControlLabel
                    control={<Checkbox color="secondary" checked/>}
                    label="Chargenwechsel"
                    labelPlacement="end"
                />
                <TextField
                    fullWidth
                    label="Charge"
                    className={classes.textField}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Menge"
                    className={classes.textField}
                    margin="normal"
                />
                <Grid container justify="space-between" className={classes.buttonsBelowForm}>
                    <Button color="primary" size="large">
                        <AddCircle className={classes.buttonIcon}/>Buchen
                    </Button>
                    <Button size="large">
                        <Cancel className={classes.buttonIcon}/>Abbrechen
                    </Button>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Werkstoffnachweis;