import { DECREMENT_COUNTER, INCREMENT_COUNTER, SET_UPDATE_AVAILABLE } from './actions';
import { combineReducers } from 'redux';

function counter(state = 0, action) {
    switch (action.type) {
        case INCREMENT_COUNTER:
            return state + 1;
        case DECREMENT_COUNTER:
            return state - 1;
        default:
            return state;
    }
}

function loadMasterDataActions(state = [], action) {
    return state;
}

function isUpdateAvailable(state = false, action) {
    switch (action.type) {
        case SET_UPDATE_AVAILABLE:
            return true;
        default:
            return state;
    }
}

export default combineReducers({
    counter,
    loadMasterDataActions,
    isUpdateAvailable
});