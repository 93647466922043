import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import BaseActionDispatcherMiddleware from './middleware/BaseActionDispatcherMiddleware';
import { registeredModules } from './modules/registeredModules';
import { Route } from 'react-router';
import baseReducers from './reducers';
import { loadState, saveState } from './localStorage';
import { SET_UPDATE_AVAILABLE } from './actions';

const routes = [];
const moduleReducers = {};
let middleware = [BaseActionDispatcherMiddleware];
let loadMasterDataActions = [];

for (const module of registeredModules) {
    const Component = module.startupComponent;
    routes.push(<Route key={module.name} path={module.path}><Component/></Route>);
    if (module.hasOwnProperty('reducers') && module.hasOwnProperty('reducersPrefix')) {
        moduleReducers[module.reducersPrefix] = module.reducers;
    }

    if (module.hasOwnProperty('middleware')) {
        middleware = middleware.concat(module.middleware);
    }

    if (module.hasOwnProperty('loadMasterDataActions')) {
        loadMasterDataActions = loadMasterDataActions.concat(module.loadMasterDataActions);
    }
}

const reducers = combineReducers({
    base: baseReducers,
    ...moduleReducers
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

console.log('registered middleware', middleware.map(m => m.name));

const persistedState = loadState() || { base: { loadMasterDataActions: loadMasterDataActions } };
persistedState.base.isUpdateAvailable = false;

const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => {
    saveState(store.getState());
});

ReactDOM.render(
    <Provider store={store}>
        <App routes={routes} />
    </Provider>
, document.getElementById('root'));

const config = {
    onUpdate: () => {
        store.dispatch({type: SET_UPDATE_AVAILABLE});
    }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(config);
