import React from 'react';
import {Container, Paper, Typography} from '@material-ui/core';
import useStyles from '../../../styles';
import BaseCounter from '../containers/BaseCounter';
import Temperature from '../containers/Temperature';

export default function Weather(props) {
    const { location } = props;

    const classes = useStyles();

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Wetter</Typography>
                <Typography variant="body1">Es ist gerade <Temperature/> warm in {location}.</Typography>
                <BaseCounter/>
            </Paper>
        </Container>
    );
}