import React from 'react';
import {Route, Switch} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import ModuleConfig from '../module';
import Weather from './Weather';

export default function Router(props) {
    return (
        <BrowserRouter basename={ModuleConfig.path}>
            <Switch>
                <Route path="/simbach">
                    <Weather location="Simbach a. Inn"/>
                </Route>
                <Route path="/">
                    <Weather location="Burghausen"/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}