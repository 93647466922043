import { connect } from 'react-redux';
import UpdateNotification from '../components/UpdateNotification';

function mapStateToProps(state) {
    return {
        isUpdateAvailable: state.base.isUpdateAvailable
    };
}

const UpdateNotificationFromServiceWorker = connect(
    mapStateToProps
)(UpdateNotification);

export default UpdateNotificationFromServiceWorker;