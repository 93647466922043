import React from 'react';

export default function Temperature(props) {
    const { temperature, onSetTemperature } = props;

    return (
        <span onClick={() => onSetTemperature(temperature + 1)}>
            {temperature}°C
        </span>
    );
}