import React, {useState} from 'react';
import {Box, Button, Container, Grid, TextField} from '@material-ui/core';
import useStyles from '../styles';

function NumpadInput(props) {
    const classes = useStyles();

    const [number, setNumber] = useState('');

    const addInput = inputNumber => e => {
        setNumber(number + inputNumber);
    };

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Box display="flex" flexDirection="column" style={{height: '100%'}} justifyContent="space-evenly"
                 alignItems="stretch">
                <Box>
                    <TextField variant="outlined" fullWidth value={number} disabled/>
                </Box>
                <Box flexGrow={1} style={{marginTop: '8px', marginBottom: '8px'}}>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{height: '100%'}}>
                        <Box flexGrow={1}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between"
                                 alignItems="stretch" style={{height: '100%'}}>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(7)}>7</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(8)}>8</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(9)}>9</Button>
                            </Box>
                        </Box>
                        <Box flexGrow={1}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between"
                                 alignItems="stretch" style={{height: '100%'}}>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(4)}>4</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(5)}>5</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(6)}>6</Button>
                            </Box>
                        </Box>
                        <Box flexGrow={1}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between"
                                 alignItems="stretch" style={{height: '100%'}}>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(1)}>1</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(2)}>2</Button>
                                <Button className={classes.numpadButton} fullWidth variant="contained"
                                        size="large" onClick={addInput(3)}>3</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" color="primary"
                                size="large" style={{height: '80px', marginBottom: '16px'}}>
                            Scannen
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default NumpadInput;