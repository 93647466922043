import reducers from './reducers';
import UserServiceMiddleware from './middleware/UserServiceMiddleware';
import { getUser } from './actions';
import LoadedUserBusinessCard from './containers/LoadedUserBusinessCard';

export default class ModuleConfig {
    static moduleName = 'Visitenkarte';
    static startupComponent = LoadedUserBusinessCard;
    static path = '/business-card';
    static reducers = reducers;
    static reducersPrefix = 'business-card';
    static middleware = [UserServiceMiddleware];
    static loadMasterDataActions = [getUser()];
}