import React from 'react';
import { Container, Paper, Typography } from '@material-ui/core';
import useStyles from '../../../styles';

export default function BusinessCard(props) {
    const { user } = props;

    const classes = useStyles();

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Visitenkarte</Typography>

                {user &&
                <>
                    <Typography variant="body1">{user.name}</Typography>
                    <Typography variant="body2">{user.email}</Typography>
                    {user.address &&
                        <>
                            <Typography variant="body2">{user.address.street}</Typography>
                            <Typography variant="body2">{`${user.address.zipcode} ${user.address.city}`}</Typography>
                        </>
                    }
                </>
                }
            </Paper>
        </Container>
    );
}