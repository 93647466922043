export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';
export const LOAD_MASTER_DATA = 'LOAD_MASTER_DATA';
export const SET_UPDATE_AVAILABLE = 'SET_UPDATE_AVAILABLE';

export function incrementCounter() {
    return { type: INCREMENT_COUNTER };
}

export function decrementCounter() {
    return { type: DECREMENT_COUNTER };
}

export function loadMasterData() {
    return { type: LOAD_MASTER_DATA };
}