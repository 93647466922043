import React from 'react';
import {Container, Paper, TextField, Typography, Button, Grid} from '@material-ui/core';
import {AddCircle, Cancel} from '@material-ui/icons';
import useStyles from '../styles';

function Herstellerbescheinigung(props) {
    const classes = useStyles();

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Dokumente</Typography>
                <TextField
                    fullWidth
                    disabled
                    label="Benutzername"
                    value="Demo-User"
                    className={classes.textField}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="System-ID"
                    className={classes.textField}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Blatt"
                    className={classes.textField}
                    margin="normal"
                />
                <Typography variant="h6" component="h2">Meldung</Typography>
                <Typography variant="body1">Hiermit wird die Prüfung laut Richtlinie 97/23/EG bestätigt und dokumentiert.</Typography>
                <Grid container justify="space-between" className={classes.buttonsBelowForm}>
                    <Button color="primary" size="large">
                        <AddCircle className={classes.buttonIcon}/>Buchen
                    </Button>
                    <Button size="large">
                        <Cancel className={classes.buttonIcon}/>Abbrechen
                    </Button>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Herstellerbescheinigung;