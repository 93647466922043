import React from 'react';
import {Button, Container, Paper, Typography} from '@material-ui/core';
import useStyles from '../styles';
import {useHistory} from 'react-router';

export default function ModuleSelection(props) {
    const { modules } = props;
    const history = useHistory();
    const classes = useStyles();

    const onNavigateToModule = path => () => {
        history.push(path);
    };

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Auswahl</Typography>
                {modules.map(m => (
                    <Button key={m.moduleName} fullWidth color="primary" onClick={onNavigateToModule(m.path)}>
                        {m.moduleName}
                    </Button>
                ))}
            </Paper>
        </Container>
    );
}