import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { loadMasterData } from '../actions';
import useStyles from '../styles';

function mapDispatchToProps(dispatch) {
    return {
        onClick: () => dispatch(loadMasterData())
    }
}

function ButtonComponent(props) {
    const { onClick } = props;
    const classes = useStyles();

    return (
        <IconButton className={classes.menuButton} color="inherit" onClick={onClick}>
            <Cached/>
        </IconButton>
    );
}

export default connect(null, mapDispatchToProps)(ButtonComponent);