import module from '../module';
import Temperature from '../components/Temperature';
import { connect } from 'react-redux';
import { setTemperature } from '../actions';

function mapStateToProps(state) {
    return {
        temperature: state[[module.reducersPrefix]].temperature
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSetTemperature: temperature => dispatch(setTemperature(temperature))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Temperature);