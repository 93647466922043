import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';

function UpdateNotification(props) {
    const { isUpdateAvailable } = props;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={isUpdateAvailable}
        >
            <SnackbarContent
                message={<span id="message-id">Eine neue Version ist verfügbar. Bitte starten Sie die Anwendung neu.</span>}
            />
        </Snackbar>
    )
}

export default UpdateNotification;