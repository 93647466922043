import React, {useState} from 'react';
import {
    Button,
    Container,
    Grid,
    Paper,
    Snackbar,
    TextField,
    Typography,
    IconButton,
    SnackbarContent
} from '@material-ui/core';
import {ExitToApp, Close} from '@material-ui/icons';
import useStyles from '../styles';

function Login(props) {
    const {onLogin} = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const classes = useStyles();

    const onTryLogin = () => {
        if (username.toLowerCase() === 'demo' && password.toLowerCase() === 'demo') {
            onLogin();
        }

        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleInputKeyPress = event => {
        if (event.key === 'Enter') {
            onTryLogin();
        }
    };

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Paper className={classes.containerPaper}>
                <Typography variant="h4" component="h1">Login</Typography>
                <TextField
                    fullWidth
                    label="Benutzername"
                    className={classes.textField}
                    margin="normal"
                    value={username}
                    onChange={e => setUsername(e.currentTarget.value)}
                    onKeyPress={handleInputKeyPress}
                />
                <TextField
                    type="password"
                    fullWidth
                    label="Passwort"
                    className={classes.textField}
                    margin="normal"
                    value={password}
                    onChange={e => setPassword(e.currentTarget.value)}
                    onKeyPress={handleInputKeyPress}
                />
                <Grid container justify="space-between" className={classes.buttonsBelowForm}>
                    <Button color="primary" size="large" onClick={onTryLogin}>
                        <ExitToApp className={classes.buttonIcon}/>Login
                    </Button>
                </Grid>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <SnackbarContent
                    className={classes.error}
                    style={{backgroundColor: '#d32f2f' /* required because of a strange bug preventing className to be applied in production build */}}
                    message={<span id="message-id">Falscher Benutzername oder falsches Passwort.</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleCloseSnackbar}
                        >
                            <Close/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </Container>
    );
}

export default Login;