import {makeStyles} from '@material-ui/core';
import {amber, green, red} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    numpadButton: {
        margin: '8px',
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    containerPaper: {
        padding: theme.spacing(3, 2)
    },
    container: {
        paddingTop: theme.spacing(2),
        flexGrow: 1
    },
    buttonIcon: {
        marginRight: theme.spacing(1)
    },
    redButton: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600]
        }
    },
    buttonsBelowForm: {
        marginTop: theme.spacing(2)
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    popover: {
        padding: theme.spacing(2)
    }
}));

export default useStyles;